body {
    font-family: 'Poppins', sans-serif;
}


/* HeaderStyles.css */

.nav-link {
    display: inline-block; /* Permite adicionar padding */
    padding: 5px 10px; /* Ajuste o padding conforme necessário */
    transition: all 0.3s ease; /* Suaviza a transição */
}

.nav-link:hover, .nav-link:focus {
    background-color: #940110; /* Cor de fundo no hover */
    color: #fff; /* Cor do texto no hover, se você quiser alterá-la */
    border-radius: 5px; /* Adiciona bordas arredondadas, se desejado */
    margin-right: 8px;
    margin-left: 8px;
}

.btn-danger {
    background-color: #940110;
}


/* styles.css */

.banner-top-container {
    overflow: hidden;
    max-height: 100vh; /* Ajuste conforme necessário */
    margin-top: 1rem;
}

.banner-top-image {
    width: 100%;
    height: 100vh; /* Ajuste conforme necessário */
    object-fit: cover;
}

.help-icon {
    position: fixed;
    right: 20px;
    bottom: 20px;
    font-size: 24px; /* Ajuste o tamanho conforme necessário */
    cursor: pointer;
  }

  .banner-top-content {
    position: absolute;
    top: 38%; /* Centraliza verticalmente */
    left: 60%;
    transform: translate(-50%, -50%); /* Ajuste fino para centralizar exatamente */
    color: #fff; /* Cor do texto */
    background-color: rgba(255, 255, 255, 0.6); /* Fundo branco com 70% de transparência */
    padding: 20px;
    border-radius: 10px;
}


.no-underline {
    text-decoration: none;
}


.message-container {
    position: absolute;
    left: -90px; /* Ajuste a posição conforme necessário */
    background-color: white;
    color: black;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px; /* Tamanho de texto pequeno */
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.help-icon:hover .message-container {
    opacity: 1; /* Exibe a mensagem no hover */
}

.agende-title {
    font-size: 36px;
}
